import React from 'react';
import { Modal, ModalProps } from 'antd';

interface CModalProps extends ModalProps {
  // Add any additional custom props if needed
}

const CModal: React.FC<CModalProps> = ({ children, ...props }) => {
  return (
    <Modal {...props}>
      {children}
    </Modal>
  );
};

export default CModal;
