// src/components/CInput/CInput.tsx

import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

interface CInputProps extends InputProps {
  label?: string;
  error?: string;
  className?: string;
}

const PasswordInput: React.FC<CInputProps> = ({
  label,
  error,
  disabled,
  addonAfter,
  addonBefore,
  defaultValue,
  prefix,
  suffix,
  placeholder,
  className,
  name,
  type,
  value,
  onChange,
  ...props
}) => {
  const inputClassName = addonBefore || addonAfter ? '' : `inputfield ${className || ''}`;

  return (
    <div>
      {label && <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{label}</label>}
      <Input.Password
        disabled={disabled}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        defaultValue={defaultValue}
        prefix={prefix}
        suffix={suffix}
        placeholder={placeholder}
        className={inputClassName}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        {...props} // Pass remaining props to the Input component
      />
      {error && <span style={{ color: 'red', marginTop: '8px', display: 'block' }}>{error}</span>}
    </div>
  );
};

export default PasswordInput;
