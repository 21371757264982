import React, { useState, useEffect } from "react";
import CustomTabs from "../../../components/common/CTabs";
import CInput from "../../../components/common/CInput";
import { Theme } from "../../../Theme";
import CTable from "../../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Switch, Tag } from "antd";
import { changeCustomerStatus, getAllCustomers } from "../../../services/customer";
import AlertModal from "../../../components/common/AlertModal";
import CustomAlert from "../../../components/common/CAlert";
import _ from 'lodash';
import EventList from "./Events/EventList";
import CitiesList from "./Cities/CitiesList";

const tabs = [
    { key: 'event', label: 'Type of Event' },
    // { key: 'category', label: 'Categories' },
    { key: 'city', label: 'Cities' },
    // { key: 'termsAndCondition', label: 'Terms & Conditions' },
    // { key: 'cancellationPolicy', label: 'Cancellation Policy' },
];

const EventPolicyManagementList: React.FC = () => {
    
    const [activeKey, setActiveKey] = useState('event');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleTabChange = (key: string) => {
        setActiveKey(key);
      };
  


    return (
        <>
            <p className="page-heading">{t("event_policy_management")}</p>
            <CustomTabs tabs={tabs} activeKey={activeKey} onTabChange={handleTabChange} />
            {activeKey === 'event' && <EventList/>}
            {activeKey === 'city' && <CitiesList/>}

               </>
    );
};

export default EventPolicyManagementList;
