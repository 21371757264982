import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form } from "antd";
import CInput from "../../components/common/CInput";
import AuthFrame from "../../components/ui/AuthFrame";
import { Theme } from "../../Theme";
import CButton from "../../components/common/CButton";
import SuccessModal from "../../components/common/SuccessModal";
import { useMutation } from "react-query";
import { forgot } from "../../services/auth";
import CustomAlert from "../../components/common/CAlert";
import { useTranslation } from "react-i18next";
import OtpInput from 'react-otp-input';


interface ResponseType {
  message: string;
  // Add other properties as needed
}
const Otp: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {email, otpCode} = location.state;
  const [otp, setOtp] = useState(otpCode);
  const [isSuccess, setIsSuccess] = useState(false);
  const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
    message: '',
    type: 'success',
    visible: false,
  });
  const [forgotData, setForgotData] = useState<{ emailOrPhoneNumber: string; response: ResponseType }>({
    emailOrPhoneNumber: '',
    response: { message: '' },
  });

//   const { mutate, isLoading } = useMutation(forgot, {
//     onSuccess: (response) => {
//       if (response.succeeded) {
//         setForgotData(prev => ({ ...prev, response }));
//         setIsSuccess(true);
//       } else {
//         setAlert({ message: response.message, type: 'error', visible: true });
//       }
//     },
//     onError: (error: any) => {
//       setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
//     },
//   });

  const onFinish = (values: { email: string }) => {
    navigate('/reset', { state: { email, otpCode } });
    // const updatedData = { ...forgotData, emailOrPhoneNumber: values.email, type: 'Email' };
    // setForgotData(updatedData);

    // mutate(updatedData);
  };

  return (
    <>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
      <div className="main-div">
        <div className="strech-man-div">
          <p className="event-planner">{t("event_planner")}</p>
        </div>
        <div className="auth-form-div d-flex align-items-center justify-content-center" style={{ margin: "20px" }}>
          <div className="auth-form-inner" style={{ textAlign: "left", margin: "20px", width: '43%' }}>
            <img src={Theme.icons.icn_main_logo} alt="logo" className="auth-logo" />
            <p className='login-heading mt-3 mb-0'>{t("verification_code")}</p>
            <p className="login-des">{t('verification_desc') + email}</p>
            <SuccessModal
              visible={isSuccess}
              message={forgotData?.response?.message}
              onOk={() => navigate('/reset', { state: { email, otpCode } })}
            />
            <Form
              name="login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
<OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      containerStyle={{justifyContent:'space-between'}}
      inputStyle={{borderRadius:'12px', height:'47px', width:'37px', border:'1px solid #A6A4A4'}}
      renderInput={(props) => <input {...props} />}
    />
              {/* <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('email_validation'),
                    validator: (_, value) => {
                      if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(t('email_error'));
                      }
                    },
                  },
                ]}
              >
                <CInput
                  label={t('email_address')}
                  placeholder={t('email_placeholder')}
                />
              </Form.Item> */}
              <p style={{textAlign:'center'}} className="mt-4 m-0 detail-label">{t("Resend Code")}</p>
              <CButton loading={false} disabled={false} className="mt-4 auth-btn" htmlType="submit">{t("Verify")}</CButton>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
