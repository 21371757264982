import instance from "./api/api_instance";


export const login = async (credentials: object) => {
  const response = await instance.post('AdminAuth/Login', credentials);
  return response.data;
};

export const forgot = async (credentials: object) => {
  const response = await instance.post('AdminAuth/ForgotPassword', credentials);
  return response.data;
};

export const reset = async (credentials: object) => {
  const response = await instance.post('AdminAuth/ResetPassword', credentials);
  return response.data;
};

