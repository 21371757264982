import React, { useEffect, useState } from 'react'; 
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/Forgot';
import ResetPassword from './pages/Auth/Reset';
import PortalScreen from './pages/layout/PortalScreen';
import ManageUserList from './pages/MangeUsers/ManageInternalUser/ManageUserList';
import AddNewUser from './pages/MangeUsers/ManageInternalUser/AddNewUser';
import i18n from 'i18next';
import 'leaflet/dist/leaflet.css';
import ViewUser from './pages/MangeUsers/ManageInternalUser/ViewUser';
import ManageCustomerList from './pages/MangeUsers/ManageCustomer/ManageCustomerList';
import ManageFreelancerList from './pages/MangeUsers/ManageFreelancer/ManageFreelancerList';
import ViewFreelancer from './pages/MangeUsers/ManageFreelancer/ViewFreelancer';
import EventPolicyManagementList from './pages/Settings/EventPolicyMangement/EventPolicyManagemetList';
import AddEventType from './pages/Settings/EventPolicyMangement/Events/AddEvent';
import AddCity from './pages/Settings/EventPolicyMangement/Cities/AddCity';
import Otp from './pages/Auth/Otp';
import VendorOnboardingList from './pages/OnBoarding/Vendor/VendorOnboardingList';
import VendorOnBoarding from './pages/OnBoarding/Vendor/OnBoarding/VendorOnboarding';
import ViewVendor from './pages/OnBoarding/Vendor/ViewVendor';

function App() {

  

useEffect(() => {
  const currentLanguage = i18n.language;
  document.documentElement.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
}, [i18n.language]);


useEffect(() => {
  const handleLanguageChange = (lng: string) => {
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  i18n.on('languageChanged', handleLanguageChange);

  // Clean up the listener when the component unmounts
  return () => {
    i18n.off('languageChanged', handleLanguageChange);
  };
}, []);

  return (
    <Routes>
    {/* put url base here and nest children routes */}
    <Route index element={<Login />} />
    <Route path="home" element={<Home />} />
    <Route path="forgot" element={<ForgotPassword />} />
    <Route path="otp" element={<Otp />} />
    <Route path="reset" element={<ResetPassword />} />
    <Route path="jive" element={<PortalScreen />} >
    <Route path="manageInternalUser" element={<ManageUserList />} />
    <Route path="manageCustomer" element={<ManageCustomerList />} />
    <Route path="manageFreelancer" element={<ManageFreelancerList />} />
    <Route path="vendorList" element={<VendorOnboardingList />} />
    <Route path="eventPolicyManagementList" element={<EventPolicyManagementList />} />
    <Route path="addNewUser" element={<AddNewUser />} />
    <Route path="addEventType" element={<AddEventType />} />
    <Route path="vendorOnboarding" element={<VendorOnBoarding />} />
    <Route path="addCity" element={<AddCity />} />
    <Route path="viewUser" element={<ViewUser />} />
    <Route path="viewFreelancer" element={<ViewFreelancer />} />
    <Route path="viewVendor" element={<ViewVendor />} />
     </Route>
    
    </Routes>
  );
}

export default App;
