
import icn_main_logo from '../assets/Icons/icn_jive_logo.svg';
import icn_success_modal from '../assets/Icons/icn_tick_circle.svg';
import icn_notification  from '../assets/Icons/icn_notification_header.svg';
import icn_search  from '../assets/Icons/icn_search.svg';
import icn_user_add  from '../assets/Icons/icn_add_user.svg';
import icn_arrow_back  from '../assets/Icons/icn_arrow_back.svg';
import icn_view_list  from '../assets/Icons/icn_view_list.svg';
import icn_white_calendar  from '../assets/Icons/icn_white_calendar.svg';
import icn_user_company  from '../assets/Icons/icn_user_company.svg';
import icn_services  from '../assets/Icons/icn_services.svg';
import icn_portfolio  from '../assets/Icons/icn_portfolio.svg';
import icn_booking  from '../assets/Icons/icn_booking.svg';
import icn_license  from '../assets/Icons/icn_license.svg';
import icn_payment  from '../assets/Icons/icn_payment.svg';
import icn_terms  from '../assets/Icons/icn_terms.svg';
import icn_support  from '../assets/Icons/icn_support.svg';
import icn_link  from '../assets/Icons/icn_link.svg';
import icn_sorting  from '../assets/Icons/icn_sorting.svg';
import icn_add_btn  from '../assets/Icons/icn_add_btn.svg';
import icn_gps_location from '../assets/Icons/icn_gps_location.svg';
import icn_image_uploader from '../assets/Icons/icn_image_uploader.svg';
import icn_tick from '../assets/Icons/icn_tick.svg';
import icn_gallery from '../assets/Icons/icn_gallery.svg';
import icn_cross from '../assets/Icons/icn_cross.svg';
import icn_terms_condition from '../assets/Icons/icn_terms_condition.svg';
import icn_edit_list from '../assets/Icons/icn_edit_list.svg';

export const Theme = {
    colors: {
      primary: '#3A2252',
      primaryTint: '#CCE0F1',
      primmaryTint03: '#80B3DB',
      gray: '#D9D9D9',
      grey02: '#EBEBEB',
      grey03: '#F0F0F0',
      grey04: '#F5F5F5',
      grey05: '#F9F9F9',
      grey08: '#717171',
      Gray48: '#7A7A7A',
      successTint: '#CEF4E5',
      success: '#09C97F',
      error: '#F95668',
      errorTint: '#FA7281',
      white: '#FFFFFF',
      offwhite: '#EFEFEF',
      contentColor: '#F1F1F1',
      tabsColor: '#FAFAFA',
      black: '#000000',
      cyan: '#00CEC1',
      lightgreen: '#CCF3F1',
      blue: '#0094FF',
      appBackground: '#FFFFFF',
      appBackground2: '#F7F8FC',
      purple_light: '#E9E5FE',
      purple_light2: '#3926684D',
      purple: '#918EC5',
      gray_light: '#F5F6FA',
      pink_light: '#EFBBB040',
      borderColor: '#918EC5',
      borderColor2: '#836BA8',
      buttonColor: '#392668',
      BlueText: '#1069BB',
      primaryText: '#0000008a',
      transparentBlack7: 'rgba(0, 0, 0, 0.7)',
      blueLight: '#0047FF1A',
      greenLight: '#DCEDD3',
      redLight: '#F5DADD',
      placeholderColor: '#00000040',
      red: '#DB1F35',
      yellow: '#F5C004',
      redShade: '#FF4444',
      alertColor: '#FFC700',
      alertCircle: '#FFF3C9',
      redCircle: '#FFDEDE',
      alertButton: '#F36565',
      green: '#1ACE2C',
      greenShade: '#08DE1D',
      consultationScreenButtons: '#ECEBEB',
      tertiary:'#F29E0F',
      borderColorPrimary: 'rgba(0, 0, 0, 0.1)',
    },
    icons: {
      icn_main_logo,
      icn_success_modal,
      icn_notification,
      icn_user_add,
      icn_search,
      icn_view_list,
      icn_arrow_back,
      icn_white_calendar,
      icn_user_company,
      icn_services,
      icn_portfolio,
      icn_booking,
      icn_license,
      icn_payment,
      icn_terms,
      icn_support,
      icn_link,
      icn_sorting,
      icn_add_btn,
      icn_gps_location,
      icn_image_uploader,
      icn_tick,
      icn_gallery,
      icn_cross,
      icn_terms_condition,
      icn_edit_list,
      icn_app_logo: require('../assets/Icons/icn_app_logo.png'),
      icn_delete_list: require('../assets/Icons/icn_delete_list.png'),
    },
    images:{
      img_placeholder: require('../assets/Images/dummy-img.png'),
      img_bg_auth: require('../assets/Images/bg-auth.png'),
    }
    // fonts: {
    //   Light: 'Nunito-Light',
    //   Regular: 'Nunito-Regular',
    //   Medium: 'Nunito-Medium',
    //   SemiBold: 'Nunito-SemiBold',
    //   Bold: 'Nunito-Bold',
    // },
  };
  

  