import instance from "./api/api_instance";

export const getAllCustomers = async (credentials: object) => {
  const response = await instance.get("Customer/GetAllCustomers", {
    params: credentials,
  });
  return response.data;
};

export const changeCustomerStatus = async (credentials: object) => {
    const response = await instance.post('AppUser/ChangeUserStatus', credentials);
    return response.data;
  };