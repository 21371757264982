import instance from "./api/api_instance";

export const getAllManageUser = async (credentials: object) => {
  const response = await instance.get("AdminUsers/GetAllAdminUsers", {
    params: credentials,
  });
  return response.data;
};

export const getAdminUserRole = async () => {
  const response = await instance.get("AdminRoles/GetAdminUserRole");
  return response.data;
};
export const createAdminUser = async (formData: FormData) => {
  const response = await instance.post(
    "AdminUsers/CreateAdminUser",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const editAdminUser = async (formData: FormData) => {
  const response = await instance.post(
    "AdminUsers/UpdateAdminUser",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getCountries = async () => {
    const response = await instance.get("Country/GetAllCountries");
    return response.data;
  };


  export const changeUserStatus = async (credentials: object) => {
    const response = await instance.post('AdminUsers/ChangeUserStatus', credentials);
    return response.data;
  };