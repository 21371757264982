import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Switch, Card, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CInput from '../../../../../components/common/CInput';
import CButton from '../../../../../components/common/CButton';
import { useMutation, useQuery } from 'react-query';
import { SaveSupportCommunicationInfo } from '../../../../../services/freelancer';
import CustomAlert from '../../../../../components/common/CAlert';
import CustomSelect from '../../../../../components/common/CSelect';
import { useNavigate } from 'react-router-dom';

interface PaymentBillingInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
}


const preferedCommunication = [
    { value: 'Email', label: 'Email' },
    { value: 'PhoneNumber', label: 'Phone Number' },
    { value: 'WhatsApp', label: 'Whats App' },
];
const SupportCommunication: React.FC<PaymentBillingInformationProps> = ({ moveTo, state, isVendorDetailNotEmpty }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);

    useEffect(() => {
        if (isVendorDetailNotEmpty) {
            console.log('data present');
            form.setFieldsValue(state);
        } else {
            console.log('data not present');
        }
    }, [])

    const { mutate, isLoading } = useMutation(SaveSupportCommunicationInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if(exit){
                    navigate('/jive/vendorList');
                }else{
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSave = (values: any) => {
        const obj = {
            ...values,
            id: isVendorDetailNotEmpty ? state?.id : null,
        }
        mutate(obj);
    }

    const handleSaveAndExit = () => {
        setExit(true);
        form.submit();
    };


    return (
        <>

            <p className='user-name'>{t("Support & Communication")}</p>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
            >
                <div className='row'>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="personName"
                            rules={[{ required: true, message: t("Please enter the contact person") }]}
                        >
                            <CInput label={t("Contact Person")} placeholder={t("Enter Contact Person")} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="communicationMethod"
                            rules={[{ required: true, message: t("Please enter the preferred communication") }]}
                        >
                            <CustomSelect
                                label={t("Preferred Communication")}
                                options={preferedCommunication}
                                placeholder={t("Enter Preferred Communication")}
                            />
                        </Form.Item>
                    </div>


                   
                    <Row gutter={8} style={{ marginTop: '15px' }}>
                        <Col span={4}>
                            <CButton className='cancelBtn' loading={isLoading && exit} onClick={handleSaveAndExit}>
                                Save & Exit
                            </CButton>
                        </Col>
                        <Col span={4}>
                            <CButton loading={isLoading && !exit} style={{ width: '100%' }} htmlType="submit">
                                Save
                            </CButton>
                        </Col>
                    </Row>
                </div>

            </Form >

            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />

        </>
    );
};

export default SupportCommunication;
