import React, { useState, useEffect } from "react";
import CInput from "../../../../components/common/CInput";
import { Theme } from "../../../../Theme";
import CTable from "../../../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Switch, Tag } from "antd";
import { changeCustomerStatus, getAllCustomers } from "../../../../services/customer";
import AlertModal from "../../../../components/common/AlertModal";
import CustomAlert from "../../../../components/common/CAlert";
import _ from 'lodash';
import CButton from "../../../../components/common/CButton";
import { getAllCities, getAllEvents } from "../../../../services/lov";

const tabs = [
    { key: 'event', label: 'Type of Event' },
    // { key: 'category', label: 'Categories' },
    { key: 'city', label: 'Cities' },
    // { key: 'termsAndCondition', label: 'Terms & Conditions' },
    // { key: 'cancellationPolicy', label: 'Cancellation Policy' },
];

const CitiesList: React.FC = () => {
    const [userWillActive, setUserWillActive] = useState<{ show: boolean; status: boolean; id: number | null }>({
        show: false,
        status: false,
        id: null,
    });
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error'; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    // Get activeKey, pageNumber, and pageSize from URL search params
    // const activeKey = searchParams.get('Status') || 'AllUser';
    const pageNumber = Number(searchParams.get('PageNumber')) || 1;
    const pageSize = Number(searchParams.get('PageSize')) || 10;
    const search = searchParams.get('Search') || "";

    // Update search params when the tab changes
    // const handleTabChange = (key: string) => {
    //     setSearchParams({ Status: key, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });
    // };

    // Pagination handling
    const handleTableChange = (page: number, pageSize: number) => {
        setSearchParams({ 
            // Status: activeKey, 
            PageNumber: page.toString(), 
            PageSize: pageSize.toString() }, { replace: true });
    };

    const handleSearchChange =  _.debounce((e:any) => {
        setSearchParams({ 
            // Status: activeKey, 
            PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
    },1000)


    const columns: ColumnsType = [
        {
            title: t("id"),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("country_name"),
            dataIndex: 'countryName',
            key: 'name',
        },
        {
            title: t("city_name"),
            dataIndex: 'cityName',
            key: 'name',
        },
        {
            title: t('action'),
            render: (text, record) => (
                <div className="d-flex  align-items-center">
                   
        {/* <img src={Theme.icons.icn_view_list} alt="icn_user_add" style={{ height: '20px' }} 
        // onClick={() => navigate('/jive/viewUser', {state:{record}})}
         /> */}
        <img src={Theme.icons.icn_edit_list} alt="icn_user_edit" style={{ height: '20px' }} 
        onClick={() => navigate('/jive/addCity', {state:{record}})} 
        />
                </div>
            ),
            key: 'action',
        },
    ];

    const { data, error, isLoading } = useQuery(
        ['Cities', pageNumber, pageSize, search],
        () => getAllCities({ PageNumber: pageNumber, PageSize: pageSize, Search: search}),
        {
            keepPreviousData: true,
            onError: (err: any) => {
                setAlert({ message: err?.message || t("An error occurred"), type: 'error', visible: true });
            },
        }
    );

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 list-heading">{t("all_city_list")}</p>
                <div className="d-flex justify-content-between">
                    <CInput
                        prefix={<img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />}
                        className="searchInput"
                        placeholder={t("Search")}
                        onChange={handleSearchChange}
                    />
                    <CButton className="addUserBtn" onClick={() => navigate('/jive/addCity')}>
        <img src={Theme.icons.icn_user_add} alt="icn_user_add" style={{ height: '20px' }} />
            <p className="m-0">{t("add_city")}</p>
        </CButton>
                </div>
            </div>
            <CTable
                footer="End of Table"
                columns={columns}
                dataSource={data?.data}
                pagination={{
                    showPrevNextJumpers: true, 
                    current: pageNumber,
                    pageSize: pageSize, 
                    total: data?.totalRecords, 
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
                    ),
                }}
                loading={isLoading}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default CitiesList;
