import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Spin } from 'antd';
import CInput from "../../../../components/common/CInput";
import { Theme } from '../../../../Theme';
import CButton from '../../../../components/common/CButton';
import { useMutation, useQuery } from 'react-query';
import CustomAlert from '../../../../components/common/CAlert';
import SuccessModal from '../../../../components/common/SuccessModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addEventType } from '../../../../services/lov';

const AddEventType: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isSuccess, setIsSuccess] = useState(false);
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });


    const record = location.state?.record;



    const { mutate, isLoading } = useMutation(addEventType, {
        onSuccess: (response) => {
            if (response.succeeded) {
                setIsSuccess(true);
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    useEffect(() => {
        if (location.state?.record) {
            form.setFieldsValue({
                Name: location.state.record.name,

            });
        }
    }, [location.state]);

    const handleSave = (values: any) => {
        const obj = {
            ...values,
            ...(record && { id: record.id }),

        };
        mutate(obj);
    };


    return (
        <>
            <div style={{ padding: '20px', color: Theme.colors.black }}>
                <div className='d-flex align-items-center mb-4'>
                    <img src={Theme.icons.icn_arrow_back} alt="icn_user_add" style={{ height: '20px' }} onClick={() => navigate(-1)} />
                    <p className='list-heading m-0 mx-2'>{t("type_of_event")}</p>
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSave}
                    style={{ backgroundColor: Theme.colors.white, padding: '24px', borderRadius: '16px' }}
                >
                    <p className='user-name'>{`${record ? 'Edit' : "Add"} Event Type`}</p>

                    <>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name="Name"
                                    rules={[{ required: true, message: 'Please enter the event type' }]}
                                >
                                    <CInput label="Event Type" placeholder="Enter Event Type" />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={8} style={{ marginTop: '15px' }}>
                            <Col span={4}>
                                <CButton loading={isLoading} style={{ width: '100%' }} htmlType="submit">
                                    Save
                                </CButton>
                            </Col>
                        </Row>
                    </>


                </Form>
                <SuccessModal
                    visible={isSuccess}
                    message={record ? 'Event type updated successfully' : 'You have added new event type successfully.'}
                    onOk={() => navigate('/jive/eventPolicyManagementList')}
                />
                <CustomAlert
                    message={alert.message}
                    type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                    visible={alert.visible}
                    onClose={() => setAlert({ ...alert, visible: false })}
                    duration={3000}
                />
            </div>
        </>
    );
};

export default AddEventType;
