import React, { useState } from 'react'; 
// import './App.css';
import CInput from '../components/common/CInput';
import { ColumnsType } from 'antd/lib/table';
import CTable from '../components/common/CTable';
import CButton from '../components/common/CButton';
import SuccessModal from '../components/common/SuccessModal';
import AlertModal from '../components/common/AlertModal';

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
];

const data: DataType[] = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
  },
];

function Home() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(''); 
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const showConfirmModal = () => {
    setIsConfirmModalVisible(true);
  };

  const handleCancel = () => {
    setIsConfirmModalVisible(false);
  };

  const handleDelete = () => {
    setIsConfirmModalVisible(false);
    setIsSuccessModalVisible(true);
  };
  return (
    <>
    <div>
      <h1>Example Form</h1>
      <form>
        <CInput
          label="Username"
          placeholder="Enter your username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          // error={username === '' ? 'This field is required' : ''}
          addonBefore="@"
        />
        <CInput
          label="Password"
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          // error={password === '' ? 'This field is required' : ''}
          prefix={<i className="fas fa-lock"></i>}
        />
      </form>
    </div>
    <div>
      <h1>Example Table</h1>
      <CTable
        title="User Information"
        footer="End of Table"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 2 }}
      />
    </div>
    <div style={{width:'50%', display:'flex', justifyContent:'space-between'}}>
      <h1>Example Page</h1>
      {/* <CButton
        type="primary"
        onClick={() => showConfirmModal()}
        label="Primary Button"
      />
      <CButton
        type="default"
        onClick={() => alert('Default Button Clicked!')}
        label="Default Button"
        icon={<i className="fa fa-thumbs-up" />} // Example icon usage
      />
      <CButton
        type="dashed"
        onClick={() => alert('Danger Button Clicked!')}
        label="Danger Button"
        disabled={true}
      /> */}

<AlertModal
        visible={isConfirmModalVisible}
        onCancel={handleCancel}
        onDelete={handleDelete}
      />


<SuccessModal
        visible={isSuccessModalVisible}
        onOk={() => setIsSuccessModalVisible(false)}
      />
    </div>
  </>
  );
}

export default Home;
