import React, { useEffect } from 'react';
import { Alert } from 'antd';
import { Theme } from '../../Theme';

interface CustomAlertProps {
}

const Header: React.FC<CustomAlertProps> = ({  }) => {

  return (
    <div style={{ backgroundColor: '#FFFFFF', height:'80px', display:'flex', justifyContent:'flex-end', alignItems:'center', paddingRight:10}}>
       
          <img
            alt=""
            src={Theme.icons.icn_notification}
            className="me-3"
            style={{ height: "36px", width:'36px' }}
          />
     <img
            alt=""
            src={Theme.images.img_placeholder}
            style={{ borderRadius:'50%', height:'48px', width:'48px', marginLeft:10 }}
          />
   <div>
   <p className="mx-2 m-0 detail-value">Muhammad Rizwan Ahmed</p>
   <p className='mx-2 m-0 admin'>Admin</p>
   </div>
    </div>
  );
};

export default Header;
