import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form } from "antd";
import { Theme } from "../../Theme";
import CButton from "../../components/common/CButton";
import SuccessModal from "../../components/common/SuccessModal";
import PasswordInput from "../../components/common/PasswordInput";
import { useMutation } from "react-query";
import { reset } from "../../services/auth";
import CustomAlert from "../../components/common/CAlert";
import { useTranslation } from "react-i18next";


const ResetPassword: React.FC = () => {  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
    message: '',
    type: 'success',
    visible: false,
  });
  const location = useLocation();
  const [isSuccess, setIsSuccess] = useState(false);
    const { mutate, isLoading, isError, error } = useMutation(reset, {
      onSuccess: (response) => {
        if (response.succeeded) {
          setIsSuccess(true);
  
        } else {
          setAlert({ message: response.message, type: 'error', visible: true });
        }
      },
      onError: (error: any) => {
        setAlert({ message: error ? error?.message : 'An error occurred. Please try again.', type: 'error', visible: true });
      },
    });
    const onFinish = (values: { email: string }) => {
      const obj = {...location.state, ...values};
      mutate(obj); // Trigger the login API call
    
    }

  return (
    <>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
            <div className="main-div">
        <div className="strech-man-div">
            <p className="event-planner">{t("event_planner")}</p>
        </div>
        <div
          className="auth-form-div d-flex align-items-center justify-content-center"
          style={{ margin: "20px" }}
        >
          <div
            className="auth-form-inner"
            style={{
              textAlign: "left",
              margin: "20px",
              width:'43%'
            }}
          >
                   <img src={Theme.icons.icn_main_logo} alt="logo" className="auth-logo" />
        <p className='login-heading mt-3 mb-0'>{t("reset_password")}</p>
        <p className="login-des">
        {t("reset_desc")}
        </p>
        <SuccessModal
        visible={isSuccess}
        message="You have successfully reset your password"
        onOk={() => navigate('/')}
      />

<Form
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="password"
              rules={[
                {
                    required: true,
                    message: t('password_error'),
                },
                { min: 6, message: t('password_validation') },
            ]}
            >
               <PasswordInput
                                    label={t('new_password')}
                                    placeholder={t('password_placeholder')}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t('confirm_password_error'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('confirm_password_validation'))
                    );
                  },
                }),
              ]}
            >
              <PasswordInput
                                    label={t('confirm_password')}
                                    placeholder={t('password_placeholder')}
              />
            </Form.Item>
            <CButton loading={isLoading} disabled={false} className="mt-4 auth-btn" htmlType="submit">{t('save')}</CButton>
     
          </Form>

          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
