import React, { useState } from 'react';
import { Form, Input, Row, Col, Switch, Card } from 'antd';
import { Theme } from '../../../Theme';
import CustomAlert from '../../../components/common/CAlert';
import SuccessModal from '../../../components/common/SuccessModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeUserStatus } from '../../../services/manageUser';
import { useMutation } from 'react-query';
import AlertModal from '../../../components/common/AlertModal';
import { useTranslation } from 'react-i18next';
import { changeCustomerStatus } from '../../../services/customer';
import CButton from '../../../components/common/CButton';


const ViewFreelancer: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { record, module } = location.state;
    const [isSuccess, setIsSuccess] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [switchChecked, setSwitchChecked] = useState(record?.active);
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    const FinalApprovals = [
        {
            label: t("service_offerings"),
            icon: Theme.icons.icn_services,
        },
        {
            label: t("portfolio_experience"),
            icon: Theme.icons.icn_portfolio,
        },
        {
            label: t("availability_booking"),
            icon: Theme.icons.icn_booking,
        },
        {
            label: t("certifications_licenses"),
            icon: Theme.icons.icn_license,
        },
        {
            label: t("payment_billing"),
            icon: Theme.icons.icn_payment,
        },
        {
            label: t("terms_condition"),
            icon: Theme.icons.icn_terms,
        },
        {
            label: t("support_communication"),
            icon: Theme.icons.icn_support,
        },
    ]
    

    const { mutate, isLoading, isError, error } = useMutation(module === 'customer' ? changeCustomerStatus : changeUserStatus, {
        onSuccess: (response) => {
            if (response.succeeded) {
                setAlert({ message: 'Status Updated successfully!', type: 'success', visible: true });
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.message : 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error?.message}</div>;

    const handleSwitchChange = () => {
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        const newStatus = !switchChecked;
        const obj = {
            id: record?.id,
            status: newStatus,
        };
        mutate(obj);
        setSwitchChecked(newStatus);
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div style={{ padding: '20px', color: Theme.colors.black }}>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <img src={Theme.icons.icn_arrow_back} alt="icn_user_add" style={{ height: '20px' }} onClick={() => navigate(-1)} />
                    <p className='list-heading m-0 mx-2'>{t("manage_freelancer")}</p>
                </div>
                <div className='d-flex align-items-center'>
                    <CButton style={{ marginRight: '12px', backgroundColor: Theme.colors.success, borderColor: Theme.colors.success }}>Accept</CButton>
                    <CButton className='rejectBtn' style={{ colo: 'red' }}>Reject</CButton>
                    {/* <p className='m-0 admin' style={{color: Theme.colors.black}}>{switchChecked ? 'Active' : 'Inactive'}</p>
                    <Switch checked={switchChecked} onChange={handleSwitchChange} className='mx-2' /> */}
                </div>
            </div>
            <Card className='mt-3'>
                <p className='freelancer-heading m-0'>{t('freelancer_profile')}</p>
            </Card>
            <div className='row' style={{paddingRight:'0.8rem'}}>
                <div className='col-lg-3'>
                    <Card className='mt-3' style={{ borderRadius: '16px' }}>
                        <p className='initial-approval m-0'>{t('initial_approval')}</p>
                        <div className='d-flex  align-items-center mt-2 w-100 approval-tab p-2'>
                            <div className='d-flex align-items-center justify-content-center' style={{ height: '28px', width: '28px', borderRadius: '8px', backgroundColor: '#FDF0D9' }}>
                                <img
                                    src={Theme.icons.icn_user_company}
                                    alt="icn_user_company"
                                    style={{ height: '20px' }}
                                />
                            </div>
                            <p className='m-0 mx-2 admin' style={{ color: Theme.colors.tertiary }}>{t("company_information")}</p>
                        </div>
                        
                        <p className='initial-approval m-0 mt-3'>{t('final_approval')}</p>
                        {FinalApprovals?.map((item) => (
                            <div className='d-flex  align-items-center mt-3 w-100 approval-tab p-2'>
                            <div className='d-flex align-items-center justify-content-center' style={{ height: '28px', width: '28px', borderRadius: '8px', backgroundColor: Theme.colors.gray_light }}>
                                <img
                                    src={item.icon}
                                    alt="icn_user_company"
                                    style={{ height: '20px' }}
                                />
                            </div>
                            <p className='m-0 mx-2 admin'>{item.label}</p>
                        </div>
                        ))}
                    </Card>
                </div>
                <div className='col-lg-9 mt-3' style={{ backgroundColor: Theme.colors.white, padding: '24px', borderRadius: '16px', height:'min-content' }}>
                    <p className='user-name'>{t("company_information")}</p>
                    <div style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                        <div className='details-and-meet-link'>
                            <div className='row'>

                                <div className='col-lg-6'>
                                <p className='detail-label m-0'>{t("bussiness_name")}</p>
                                <p className='detail-value'>{record?.businessName}</p>
                            </div>
                            <div className='col-lg-6'>
                                <p className='detail-label m-0'>{t("person_name")}</p>
                                <p className='detail-value'>{record?.personName}</p>
                            </div>
                            <div className='col-lg-6'>
                                <p className='detail-label m-0'>{t("phone_number")}</p>
                                <p className='detail-value'>{record?.phoneNumber}</p>
                            </div>
                            <div className='col-lg-6'>
                                <p className='detail-label m-0'>{t("bussiness_address")}</p>
                                <p className='detail-value'>{record?.businessAddress}</p>
                            </div>
                            <div className='col-lg-6'>
                                <p className='detail-label m-0'>{t("web_url")}</p>
                                <p className='detail-value'>{record?.websiteURL}</p>
                            </div>
                            <div className='col-lg-6'>
                                <p className='detail-label m-0'>{t("email")}</p>
                                <p className='detail-value'>{record?.email}</p>
                            </div>
                            
                            </div>
                            <div className='d-flex justify-content-end'>
                            <div className='d-flex align-items-center justify-content-center' style={{ height: '28px', width: '28px', borderRadius: '8px', backgroundColor: '#CBF2FF' }}>
                                <img
                                    src={Theme.icons.icn_link}
                                    alt="icn_user_company"
                                    style={{ height: '20px' }}
                                />
                            </div>
                            </div>
                        </div>
                        {/* <div className='d-flex align-items-center'>
                            <div className='bold-name-circle'>
                                <p className='m-0'>FA</p>
                            </div>
                            <p className='user-name m-0 mx-2'>{record?.name}</p>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-lg-3'>
                                <p className='detail-label m-0'>{module === 'customer' ? t("date_of_joining") : t("user_role")}</p>
                                <p className='detail-value'>{module === 'customer' ? record?.joiningDate : record?.roleName}</p>
                            </div>
                            <div className='col-lg-3'>
                                <p className='detail-label m-0'>{t("phone_number")}</p>
                                <p className='detail-value'>{record?.phoneNumber}</p>
                            </div>
                            <div className='col-lg-3'>
                                <p className='detail-label m-0'>{t("email_address")}</p>
                                <p className='detail-value'>{record?.email}</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <AlertModal
                visible={isModalVisible}
                message={`Are you sure you want to ${switchChecked ? 'deactivate' : 'activate'} this user?`}
                onDelete={handleModalOk}
                onCancel={handleModalCancel}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </div>
    );
};

export default ViewFreelancer;
