import React, { useEffect } from 'react';
import { Alert } from 'antd';

interface CustomAlertProps {
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
  visible: boolean;
  onClose: () => void;
  duration?: number; // Duration in milliseconds (optional)
}

const CustomAlert: React.FC<CustomAlertProps> = ({ message, type, visible, onClose, duration = 3000 }) => {
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      // Cleanup the timer on component unmount or if the visible prop changes
      return () => clearTimeout(timer);
    }
  }, [visible, duration, onClose]);

  if (!visible) return null;

  return (
    <div style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}>
      <Alert
        message={message}
        type={type}
        showIcon
        closable
        onClose={onClose}
      />
    </div>
  );
};

export default CustomAlert;
