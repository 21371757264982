// src/components/common/CButton.tsx

import React from 'react';
import { Button, ButtonProps } from 'antd';
import { ThreeDots } from "react-loader-spinner";

interface CButtonProps extends ButtonProps {
  className?: string;
  style?: any;
  loading?: boolean;
}

const CButton: React.FC<CButtonProps> = ({className, style, loading, ...props }) => {
  const buttonClassName =   `buttonStyle ${className || ''}`;
  return (
    <Button {...props} className={buttonClassName} style={ props.disabled === true ? {backgroundColor: '#E1D7ED'} : style}>
      {loading ? (
        <ThreeDots
          height="30"
          width="30"
          radius="9"
          color="#d9d9d9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          // wrapperClassName={"d-flex align-items-center"}
          visible={true}
        />
      ) : (
        props?.children
      )}
    </Button>
  );
};

export default CButton;
