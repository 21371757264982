import instance from "./api/api_instance";

export const getAllEvents = async (credentials: object) => {
  const response = await instance.get("EventType/GetAll", {
    params: credentials,
  });
  return response.data;
};

export const addEventType = async (credentials: object) => {
    const response = await instance.post('EventType/AddUpdate', credentials);
    return response.data;
  };

  export const getAllCities = async (credentials: object) => {
    const response = await instance.get("City/GetAll", {
      params: credentials,
    });
    return response.data;
  };

  export const addCity = async (credentials: object) => {
    const response = await instance.post('City/AddUpdate', credentials);
    return response.data;
  };

// export const changeCustomerStatus = async (credentials: object) => {
//     const response = await instance.post('AppUser/ChangeUserStatus', credentials);
//     return response.data;
//   };

export const getAllParentCategory = async (credentials: object) => {
  const response = await instance.get("Category/GetParentCategoryList", credentials);
  return response.data;
};


export const getCityById = async (credentials: object) => {
  const response = await instance.get("City/GetCitiesByCountryId", {
    params: credentials,
  });
  return response.data;
};


export const getAllTermsCondition = async (credentials: object) => {
  const response = await instance.get("TermAndCondition/GetAll",{});
  return response.data;
};