import React, { useState } from 'react';
import { UserOutlined, BookOutlined, CalendarOutlined } from '@ant-design/icons';
import { Layout, Tag } from 'antd';
import { Theme } from '../../Theme';
import Header from '../../components/common/Header';
import MenuWrapper from '../../components/ui/MenuWrapper'; // Import the new MenuWrapper component
import { Outlet, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import logo from "../../assets/Icons/icn_jive_logo.svg";

const { Sider, Content } = Layout;


const items2 = [
  {
    key: 'home',
    icon: <UserOutlined />,
    label: 'Home',
    path: '/home', // Add path for navigation
  },
  {
    key: 'manageUsers',
    icon: <UserOutlined />,
    label: 'Manage Users',
    children: [
      {
        key: 'internalUser',
        label: 'Manage Internal User',
        path: '/manageInternalUser', // Add path for each child menu item
      },
      {
        key: 'customer',
        label: 'Manage Customer',
        path: '/manageCustomer',
      },
      {
        key: 'vendor',
        label: 'Manage Vendor',
        path: '/manageFreelancer',
      },
      {
        key: 'freelancer',
        label: 'Manage Freelancer',
        path: '/manageFreelancer',
      },
    ],
  },
  {
    key: 'manageBooking',
    icon: <UserOutlined />,
    label: 'Manage Booking',
    children: [
      {
        key: 'serviceBooking',
        label: 'Service Booking',
        path: '',
      },
      {
        key: 'customEventBooking',
        label: 'Custom Event Booking',
        path: '',
      },
    ],
  },
  {
    key: 'onboarding',
    icon: <UserOutlined />,
    label: 'Onboarding',
    children: [
      {
        key: 'vendorOnbaording',
        label: 'Vendor Onbaording',
        path: '/vendorList',
      },
      {
        key: 'freelancerOnboarding',
        label: 'Freelancer Onboarding',
        path: '',
      },
    ],
  },
  {
    key: 'settings',
    icon: <UserOutlined />,
    label: 'Settings',
    children: [
      {
        key: 'manageProfile',
        label: 'Manage Profile',
        path: '',
      },
      {
        key: 'roleAndPreviliges',
        label: 'Roles & Previliges',
        path: '',
      },
      {
        key: 'manageListOfValues',
        label: 'Manage List of values',
        path: '/EventPolicyManagementList',
      },
    ],
  },
];

const PortalScreen: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('internalUser');
  const [highlightStyle, setHighlightStyle] = useState({ top: 0, height: 0 });
  const navigate = useNavigate();
  const handleMenuClick = (e: any) => {
    console.log('e==>>', e);
    setSelectedKey(e.key);

     // Find the menu item that was clicked
  const menuItem = items2
  .flatMap((item) => (item.children ? item.children : [item]))
  .find((item) => item.key === e.key);

// Navigate to the path of the clicked menu item
if (menuItem?.path) {
  console.log('menuItme==+>>>', menuItem?.path);
  navigate(`/jive${menuItem.path}`);
}

    // Find the submenu item to highlight
    const submenu = items2.find((item: any) => item.key === 'manageUsers');
    console.log('submenu===>>>', submenu);
    if (submenu && 'children' in submenu && Array.isArray(submenu.children)) {
      const index = submenu.children.findIndex((item: any) => item.key === e.key);
      if (index !== -1) {
        const menuItemHeight = 45; // The height of each menu item (should match CSS height)
        const top = index * menuItemHeight+8;
        setHighlightStyle({ top, height: menuItemHeight });
      }
    }
  };

  const onLogOut = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  }
  return (
    <Layout style={{ height: '100vh' }}>
      <Sider width={260} style={{ background: Theme.colors.white }}>
        <div className='mt-4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            alt="logo"
            src={Theme.icons.icn_main_logo}
          />
        </div>
        <MenuWrapper
          items={items2}
          selectedKey={selectedKey}
          highlightStyle={highlightStyle}
          onClick={handleMenuClick}
        />
              <div className='d-flex align-items-center justify-content-center w-100'>
              <Tag className='status-listing w-75 financeManager' onClick={onLogOut}>Logout</Tag>
              </div>
      </Sider>
      <Layout>
        <Header />
        <Content
          style={{
            padding: '24px',
            margin: 0,
            backgroundColor: Theme.colors.contentColor,
            overflowY:'scroll'
          }}
        >
          <Outlet/>
        </Content>
      </Layout>
    </Layout>
  );
};

export default PortalScreen;
