import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CButton from '../../../../../components/common/CButton';
import { useMutation } from 'react-query';
import { Theme } from '../../../../../Theme';
import { SaveServiceDetailInfo } from '../../../../../services/freelancer';
import CustomAlert from '../../../../../components/common/CAlert';
import { Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { useNavigate } from 'react-router-dom';

const getBase64 = (img: RcFile, callback: (imageUrl: string | ArrayBuffer | null) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};


interface VendorInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
}

const VendorDetail: React.FC<VendorInformationProps> = ({ moveTo, state, isVendorDetailNotEmpty }: any) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);

    console.log('fileList===>>', fileList.length, imageUrl);

    const handleChange = ({ fileList: newFileList }: { fileList: UploadFile[] }) => {
        setFileList(newFileList);

        const lastFile = newFileList.slice(-1)[0]?.originFileObj as RcFile;
        if (lastFile) {
            getBase64(lastFile, (url) => setImageUrl(url));
        }
    };


    const uploadProps: UploadProps = {
        action: "",
        onChange: handleChange,
        multiple: false,

    };

    form.setFieldsValue({
        profileImagePath: fileList,
    });




    useEffect(() => {
        if (state.serviceTechnicalDescription !== null) {
            form.setFieldsValue({
                serviceTechnicalDescription: state?.serviceTechnicalDescription,
            });
        }
        if (state?.profileImagePath !== null) {
            setImageUrl(process.env.REACT_APP_S3_BUCKET_URL + state?.profileImagePath)
        }
    }, [])

    const { mutate, isLoading } = useMutation(SaveServiceDetailInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if(exit){
                    navigate('/jive/vendorList');
                }else{
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSave = (values: any) => {
        console.log('values---.>>>>', values);
        const obj = {
            ServiceTechnicalDescription: values.serviceTechnicalDescription,
            id: state.id
        }

        const formData = new FormData();
        if(values.profileImagePath.length !== 0){
            const file = fileList[0].originFileObj as RcFile;
            formData.append('ProfileImagePath', file);
        }
        formData.append('obj', JSON.stringify(obj));
        mutate(formData);
    }

    const handleSaveAndExit = () => {
        setExit(true);
        form.submit();
    };

    return (
        <>
            <p className='user-name'>{t("Vendor Detail")}</p>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
            >
                <Form.Item
                    name="profileImagePath"
                    rules={[{ required: false, message: 'Please enter the profile image' }]}
                >
                    <Upload
                        {...uploadProps}
                    >
                        <img src={imageUrl ? imageUrl as string : Theme.icons.icn_image_uploader} alt="icn_user_add" style={{ height: '90px', width: '90px' }} />
                    </Upload>
                </Form.Item>
                <p className='user-name mt-1'>{t("Vendor Profile")}</p>

                <div className='row'>
                    <div className='col-lg-5'>

                        <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Service Technical Requirements")}</label>
                        <Form.Item
                            name="serviceTechnicalDescription"
                            rules={[{ required: true, message: 'Please enter the requirement' }]}
                        >
                            <TextArea
                                showCount
                                autoSize={({ minRows: 4, maxRows: 6 })}
                                maxLength={600}
                                placeholder="Please enter your requirement"
                                style={{ height: 150, width: 500 }}
                            />
                        </Form.Item>
                    </div>
                    <Row gutter={8} style={{ marginTop: '15px' }}>
                        <Col span={4}>
                            <CButton className='cancelBtn' loading={isLoading && exit } onClick={handleSaveAndExit}>
                                Save & Exit
                            </CButton>
                        </Col>
                        <Col span={4}>
                            <CButton loading={isLoading && !exit } style={{ width: '100%' }} htmlType="submit">
                                Save
                            </CButton>
                        </Col>
                    </Row>
                </div>

            </Form>
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />

        </>
    );
};

export default VendorDetail;
