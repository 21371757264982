import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Upload, UploadProps } from 'antd';
import CButton from '../../../../../components/common/CButton';
import CustomAlert from '../../../../../components/common/CAlert';
import CInput from '../../../../../components/common/CInput';
import { Theme } from '../../../../../Theme';
import axios from 'axios';
import { RcFile, UploadFile } from 'antd/es/upload';
import { SaveCertificationLincensesInfo } from '../../../../../services/freelancer';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const getBase64 = (file: File, callback: (url: string | ArrayBuffer | null) => void) => {
    const reader = new FileReader();
    reader.onload = () => callback(reader.result);
    reader.readAsDataURL(file);
};

interface VendorInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
}

const CertificationsLicenses: React.FC<VendorInformationProps> = ({ moveTo, state, isVendorDetailNotEmpty }: any) => {
    const [form] = Form.useForm();
    const { Dragger } = Upload;
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    useEffect(() => {
        if (state.commericalRegistrationNumber !== null) {
            form.setFieldsValue({
                CommericalRegistrationNumber: state.commericalRegistrationNumber,
            });
        }
        if (state.vendorFreelancerCertifications.length !== 0) {
            setUploadedFiles(state?.vendorFreelancerCertifications);
            form.setFieldsValue({
                certificates: uploadedFiles,
            });
        }
    }, [])

    form.setFieldsValue({
        certificates: uploadedFiles,
    });

    const { mutate, isLoading } = useMutation(SaveCertificationLincensesInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if(exit){
                    navigate('/jive/vendorList');
                }else{
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });


    const props: UploadProps = {
        name: 'file',
        multiple: true,
        beforeUpload(file) {
            const isAllowedFormat = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(file.type);
            if (!isAllowedFormat) {
                setAlert({ message: 'Unsupported file format', type: 'error', visible: true });
                return Upload.LIST_IGNORE;
            }
            if (uploadedFiles.length > 4) {
                setAlert({ message: 'You can only upload up to 4 files', type: 'error', visible: true });
                return Upload.LIST_IGNORE;
            }
            return true;
        },
        onChange(info) {
            const { file } = info;
            if (file.status) {
                const newFile = file.originFileObj || file;
                if (['image/png', 'image/jpg', 'image/jpeg'].includes(newFile.type || '')) {
                    const rcFile = newFile as RcFile;
                    getBase64(rcFile, (url) => {
                        // Avoid adding duplicate files
                        const data = uploadedFiles.some(f => f?.newFile ? f.newFile?.name : f.originalFileName === newFile.name && f?.newFile ? f.newFile?.size : f.fileLength === newFile.size);
                        console.log('some val==>>>', data);
                        if (!data) {
                            setUploadedFiles([...uploadedFiles, { newFile, preview: url }]);
                        }
                    });
                } else {
                    // Handle non-image files
                    if (!uploadedFiles.some(f => f.name === newFile.name && f.size === newFile.size)) {
                        setUploadedFiles([...uploadedFiles, { newFile }]);
                    }
                }
            }
        },
    };

    const handleRemoveFile = (file: any, fileIndex: number) => {
        console.log('file===>>>', file);
        if (file.preview) {
            const updatedFiles = uploadedFiles.filter((_, index) => index !== fileIndex);
            setUploadedFiles(updatedFiles);
        } else {
            const duplicateFiles = [...uploadedFiles];
            const index = duplicateFiles.findIndex(
                ({ id }) => id === file.id
            );
            duplicateFiles[index].deleted = true;
            setUploadedFiles(duplicateFiles);
        }
    };

    const handleSave = async (values: any) => {
        const obj = {
            CommericalRegistrationNumber: values.CommericalRegistrationNumber,
            id: state.id,
            vendorFreelancerCertifications: uploadedFiles.filter(file => !file.preview)

        }
        const formData = new FormData();
        const binaryFile = uploadedFiles.filter(file => file.preview);
        binaryFile.forEach((file) => {
            formData.append('certificates', file?.newFile as RcFile);
        });
        formData.append('obj', JSON.stringify(obj));
        mutate(formData);

    };

    const handleSaveAndExit = () => {
        setExit(true);
        form.submit();
    };


    return (
        <>
            <p className="user-name">Certifications & Licenses</p>
            <Form form={form} layout="vertical" onFinish={handleSave} initialValues={{ remember: true }}>
                <div className="row flex-column">
                    <div className="col-lg-4">
                        <Form.Item
                            name="CommericalRegistrationNumber"
                            rules={[{ required: true, message: 'Please enter the registration number' }]}
                        >
                            <CInput label="Commercial Registration Number" placeholder="Enter Registration Number" />
                        </Form.Item>
                    </div>

                    <Form.Item
                        name="certificates"
                        rules={[{ required: true, message: 'Please enter the certificates' }]}
                    >
                        <label className="inputfield-label mt-2">Commercial & License Documents</label>

                        <Dragger {...props} className="col-lg-4 drag-drop-upload py-2 mt-2 mx-2" style={{width:'35%'}}>
                            <img src={Theme.icons.icn_gallery} alt="gallery-icon" className="category-icon mb-0" />
                            <p className="user-name m-0">Drag & Drop or Upload</p>
                            <p className="detail-label m-0">File Formats : PNG, JPG, PDF</p>
                            <p className="detail-label m-0">File Size 5MB max</p>
                            <div className="d-flex justify-content-center">
                                <CButton loading={false} className="upload-btn mt-1">
                                    Upload
                                </CButton>
                            </div>
                        </Dragger>
                    </Form.Item>

                    <div className="row w-50">
                        {uploadedFiles?.filter(file => file.deleted !== true).map((file, index) => (
                            <div className="col-lg-6" key={index}>
                                <div className="d-flex align-items-center justify-content-between p-2 preview-image-container">
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={file.preview ? file.preview || URL.createObjectURL(file.newFile) : process.env.REACT_APP_S3_BUCKET_URL + file.path}
                                            alt={file?.newFile ? file.newFile?.name : file.originalFileName}
                                            className="category-icon mb-0"
                                            style={{ width: '35px', height: '35px' }}
                                        />
                                        <div className="mx-1">
                                            <p className="detail-label m-0">{file?.newFile ? file.newFile?.name : file.originalFileName}</p>
                                            <p className="detail-label m-0">{(file?.newFile ? file.newFile?.size : file.fileLength / 1024).toFixed(2)} MB</p>
                                        </div>
                                    </div>
                                    <img
                                        src={Theme.icons.icn_cross}
                                        alt="remove-icon"
                                        className="mb-0"
                                        style={{ height: '16px', width: '16px', cursor: 'pointer' }}
                                        onClick={() => handleRemoveFile(file, index)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>


                    <Row gutter={8} style={{ marginTop: '15px' }}>
                        <Col span={4}>
                            <CButton className='cancelBtn' loading={isLoading && exit} onClick={handleSaveAndExit}>
                                Save & Exit
                            </CButton>
                        </Col>
                        <Col span={4}>
                            <CButton loading={isLoading && !exit} style={{ width: '100%' }} htmlType="submit">
                                Save
                            </CButton>
                        </Col>
                    </Row>
                </div>
            </Form>

            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default CertificationsLicenses;
