import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLngExpression, Icon } from 'leaflet';
import { useState, useEffect, useRef } from 'react';

// Importing leaflet icon images
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Define the marker icon properly
const customMarkerIcon = new Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

interface CurrentLocationMapProps {
  onLocationSelect: (location: string) => void;
}

const SetViewOnChange = ({ position }: { position: LatLngExpression }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(position, map.getZoom());
  }, [position, map]);
  return null;
};

const CurrentLocationMap: React.FC<CurrentLocationMapProps> = ({ onLocationSelect }) => {
  const [currentPosition, setCurrentPosition] = useState<LatLngExpression>([51.505, -0.09]);
  const [currentAddress, setCurrentAddress] = useState<string>('');
  const markerRef = useRef(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition([latitude, longitude]);

          // Get initial address via geocoding
          fetchAddress(latitude, longitude);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  // Function to fetch address from coordinates using Nominatim API
  const fetchAddress = async (lat: number, lng: number) => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
    );
    const data = await response.json();
    const address = data.display_name || 'Address not found';
    setCurrentAddress(address);
    onLocationSelect(address); // Pass address to parent
  };

  // Handle the drag event and update the position
  const handleDragEnd = () => {
    const marker = markerRef.current;
    if (marker != null) {
      const newPosition = (marker as any).getLatLng();
      setCurrentPosition([newPosition.lat, newPosition.lng]);

      // Fetch the new address based on the new coordinates
      fetchAddress(newPosition.lat, newPosition.lng);
    }
  };

  return (
    <MapContainer center={currentPosition} zoom={13} style={{ height: '400px', width: '100%' }}>
      <SetViewOnChange position={currentPosition} /> {/* Center map on current position */}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker
        position={currentPosition}
        draggable={true} // Enable dragging
        eventHandlers={{ dragend: handleDragEnd }} // Handle drag event
        ref={markerRef}
        icon={customMarkerIcon} // Use custom marker icon
      >
        <Popup>{currentAddress || 'Loading address...'}</Popup> {/* Display current address */}
      </Marker>
    </MapContainer>
  );
};

export default CurrentLocationMap;
