import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Spin } from 'antd';
import CInput from "../../../components/common/CInput";
import { Theme } from '../../../Theme';
import CustomSelect from '../../../components/common/CSelect';
import Phone from '../../../components/common/CPhone';
import CButton from '../../../components/common/CButton';
import { createAdminUser, editAdminUser, getAdminUserRole, getCountries } from '../../../services/manageUser';
import { useMutation, useQuery } from 'react-query';
import CustomAlert from '../../../components/common/CAlert';
import SuccessModal from '../../../components/common/SuccessModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingView from '../../../components/common/LoadingView';

const AddNewUser: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedRole, setSelectedRole] = useState<string | undefined>(undefined);
    const [phoneDetails, setPhoneDetails] = useState<{ phoneNumber: string; countryId: number; prefix: string }>({ phoneNumber: '', countryId: 0, prefix: '' });
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });


    const record = location.state?.record;

    // Fetch user roles
    const { data, error, isLoading } = useQuery('adminUserRoles', getAdminUserRole);

    // Fetch countries
    const { data: countryData, error: countryError, isLoading: isCountryLoading } = useQuery('countries', getCountries);

    const { mutate, isLoading: addLoading } = useMutation(record ? editAdminUser : createAdminUser, {
        onSuccess: (response) => {
            if (response.succeeded) {
                setIsSuccess(true);
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    useEffect(() => {
        if (location.state?.record) {
            form.setFieldsValue({
                Name: location.state.record.name,
                Email: location.state.record.email,
                PhoneNumber: location.state.record.prefixCode + location.state.record.shortPhoneNumber,
                RoleId: location.state.record.roleId,
            });
        }
    }, [location.state, form]);

    const handleSave = (values: any) => {
        const obj = {
            ...values,
            CountryId: phoneDetails?.countryId,
            ShortPhoneNumber: phoneDetails?.phoneNumber,
            ...(record && { id: record.id }),

        };
        const formData = new FormData();
        formData.append('obj', JSON.stringify(obj));
        mutate(formData);
    };

    const getData = () => {
        if (!data) return []; // Return an empty array if data is undefined
        return data.data.map((x: any) => ({
            value: x.id,
            label: x.roleName
        }));
    };

    // if (isLoading || isCountryLoading) return <div>Loading...</div>;
    // if (error || countryError) {
    //     const errorMessage = (error as Error | null)?.message || (countryError as Error | null)?.message;
    //     return <div>Error: {errorMessage}</div>;
    // }

    const handlePhoneNumberChange = (details: { phoneNumber: string; countryId: number; prefix: string }) => {
        setPhoneDetails(details);
        if (details?.phoneNumber !== '') {
            form.setFieldsValue({
                PhoneNumber: details?.prefix + details?.phoneNumber,
            });
        }
    };


    return (
        <>
            {/* <LoadingView isVisible={isLoading}/> */}
            <div style={{ padding: '20px', color: Theme.colors.black }}>
                <div className='d-flex align-items-center mb-4'>
                    <img src={Theme.icons.icn_arrow_back} alt="icn_user_add" style={{ height: '20px' }} onClick={() => navigate(-1)} />
                    <p className='list-heading m-0 mx-2'>{t("manage_users")}</p>
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSave}
                    style={{ backgroundColor: Theme.colors.white, padding: '24px', borderRadius: '16px' }}
                >
                    <p className='user-name'>{`${record ? 'Edit' : "Add"} New Users`}</p>
                    {isLoading  || isCountryLoading ? (
                        <div className='d-flex align-items-center justify-content-center' style={{height:'200px'}} >
                            <Spin />
                        </div>
                    ): (
                        <>
                              <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="Name"
                            rules={[{ required: true, message: 'Please enter the username' }]}
                        >
                            <CInput label="User Name" placeholder="Enter Username" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="Email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter a valid email",
                                    validator: (_, value) => {
                                        if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject("Please input your E-mail!");
                                        }
                                    },
                                },
                            ]}
                        >
                            <CInput disabled={record ? true : false} label="Email" placeholder="Enter Email" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="PhoneNumber"
                            rules={[{ required: phoneDetails.phoneNumber === '' ? true : false, message: 'Please enter the phone number' }]}
                        >
                            <Phone
                                label='Phone Number'
                                countries={countryData?.data}
                                onPhoneNumberChange={handlePhoneNumberChange}
                                initialValue={location.state?.record?.phoneNumber}
                                isDisabled={record ? true : false}
                            />

                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="RoleId"
                            rules={[{ required: true, message: 'Please choose a role' }]}
                        >
                            <CustomSelect
                                label='User Role'
                                options={getData()}
                                value={selectedRole}
                                onChange={(value) => setSelectedRole(value)}
                                placeholder="Choose Role"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} style={{ marginTop: '16px' }}>
                    <Col span={4}>
                        <CButton className='cancelBtn' onClick={() => record ? navigate('/jive/manageInternalUser') : form.resetFields()}>
                            Cancel
                        </CButton>
                    </Col>
                    <Col span={4}>
                        <CButton loading={addLoading} style={{ width: '100%' }} htmlType="submit">
                            Save
                        </CButton>
                    </Col>
                </Row>
                        </>
                    )}

              
                </Form>
                <SuccessModal
                    visible={isSuccess}
                    message={record ? 'User updated successfully' : 'You have added new user successfully.'}
                    onOk={() => navigate('/jive/manageInternalUser')}
                />
                <CustomAlert
                    message={alert.message}
                    type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                    visible={alert.visible}
                    onClose={() => setAlert({ ...alert, visible: false })}
                    duration={3000}
                />
            </div>
        </>
    );
};

export default AddNewUser;
