import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Switch, Card, Modal, TimePicker, Radio, Checkbox, TimePickerProps, GetProp } from 'antd';
import { useTranslation } from 'react-i18next';
import CButton from '../../../../../components/common/CButton';
import CustomAlert from '../../../../../components/common/CAlert';
import { useMutation } from 'react-query';
import { SaveAvailabilityBookingDetailInfo } from '../../../../../services/freelancer';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

interface VendorInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
}

interface WeekdaysAvailability {
    [key: string]: boolean;
}

const AvailabilityBookingDetail: React.FC<VendorInformationProps> = ({ moveTo, state, isVendorDetailNotEmpty }: any) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);
    const [days, setDays] = useState<string[]>([]);


    const weekdays = [
        { label: 'Mon', value: 'monday' },
        { label: 'Tue', value: 'tuesday' },
        { label: 'Wed', value: 'wednesday' },
        { label: 'Thu', value: 'thursday' },
        { label: 'Fri', value: 'friday' },
        { label: 'Sat', value: 'saturday' },
        { label: 'Sun', value: 'sunday' },
    ];

    const { mutate, isLoading } = useMutation(SaveAvailabilityBookingDetailInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if(exit){
                    navigate('/jive/vendorList');
                }else{
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });


    const onChange: (time: any, timeString: string | string[], type: 'start_time' | 'end_time') => void = (time, timeString, type) => {
        console.log(time, timeString);

        const selectedTime = Array.isArray(timeString) ? timeString.join(', ') : timeString;
        form.setFieldsValue({
            [type === 'start_time' ? 'bookingStartTime' : 'bookingEndTime']: selectedTime,
        });

    };

    const handleSave = (values: any) => {
        const obj = {
            id: state?.id,
            bookingStartTime: values?.bookingStartTime,
            bookingEndTime: values?.bookingEndTime,
            bookingProcessDetail: values?.bookingProcessDetail,
            ...values?.weekdays
        }
        mutate(obj);
    }


    const handleSaveAndExit = () => {
        setExit(true);
        form.submit();
    };


    const onChangeCheckbox: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues: any) => {
        console.log('checked = ', checkedValues);
        setDays(checkedValues);

        const weekdaysAvailability: WeekdaysAvailability = weekdays.reduce((acc: WeekdaysAvailability, day) => {
            acc[day.value] = checkedValues?.includes(day.value) || false;
            return acc;
        }, {} as WeekdaysAvailability);

        form.setFieldsValue({
            weekdays: weekdaysAvailability
        });
        console.log('Submitted Data:', weekdaysAvailability);
    };

    useEffect(() => {
        if (isVendorDetailNotEmpty) {
            // form.setFieldsValue({
            //     serviceTechnicalDescription: state?.serviceTechnicalDescription
            // });
            form.setFieldsValue(state);
        }
        let selectedDays: string[] = [];

        weekdays.forEach(day => {
            console.log('d==>', day);
            if (state[day.value]) {
                selectedDays.push(day.value); // Push label (Mon, Tue, etc.) to the array
            }
        });
        setDays(selectedDays);
        console.log('selectedDays===>>>', selectedDays);

        // form.setFieldsValue({ weekdays: selectedDays });

    }, [])

    return (
        <>

            <p className='user-name'>{t("Availability & Booking Details")}</p>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
            >
                <div className='row'>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="bookingStartTime"
                            rules={[{ required: true, message: t("Please enter the start time") }]}
                        >

                            <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Booking Start Time")}</label>
                            <TimePicker className='inputfield w-100'
                             
                             defaultValue={state.bookingStartTime ? dayjs(state.bookingStartTime, 'h:mm A'): null}
                                use12Hours format="h:mm A" onChange={(time, timeString) => onChange(time, timeString, 'start_time')} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="bookingEndTime"
                            rules={[{ required: true, message: t("Please enter the end time") }]}
                        >

                            <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Booking End Time")}</label>
                            <TimePicker className='inputfield w-100' 
                            defaultValue={state.bookingEndTime ? dayjs(state.bookingEndTime, 'h:mm A') : null} 
                            use12Hours format="h:mm A" 
                            onChange={(time, timeString) => onChange(time, timeString, 'end_time')} />
                        </Form.Item>
                    </div>
                    <div className='availability-checkbox'>
                        <Form.Item
                            name="weekdays"
                            rules={[{ required: true, message: t("Please enter the available week days") }]}
                        >
                            <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Available Week Days")}</label>
                            <Checkbox.Group 
                            onChange={onChangeCheckbox} 
                            value={days} 
                            >
                                {weekdays.map((day) => (
                                    <Checkbox key={day.value} value={day.value} style={{ margin: '0 8px' }}>
                                        {day.label}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className='col-lg-5'>

                        <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Booking Process Details")}</label>
                        <Form.Item
                            name="bookingProcessDetail"
                            rules={[{ required: true, message: t("Please enter the booking details") }]}
                        >
                            <TextArea
                                showCount
                                autoSize={({ minRows: 6, maxRows: 8 })}
                                maxLength={600}
                                placeholder={t("Please enter the booking details")}
                                style={{ height: 340, width: 400 }}
                            />
                        </Form.Item>
                    </div>

                    <Row gutter={8} style={{ marginTop: '15px' }}>
                        <Col span={4}>
                            <CButton className='cancelBtn' loading={isLoading && exit } onClick={handleSaveAndExit}>
                                Save & Exit
                            </CButton>
                        </Col>
                        <Col span={4}>
                            <CButton loading={isLoading && !exit } style={{ width: '100%' }} htmlType="submit">
                                Save
                            </CButton>
                        </Col>
                    </Row>
                </div>

            </Form >

            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />

        </>
    );
};

export default AvailabilityBookingDetail;
