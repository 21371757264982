import React from 'react';
import { Tabs } from 'antd';
import { Theme } from '../../Theme';

const { TabPane } = Tabs;

interface TabItem {
    key: string;
    label: string;
  }

interface CustomTabsProps {
  tabs: TabItem[];
  activeKey: string;
  onTabChange: (key: string) => void;
}

const CustomTabs: React.FC<CustomTabsProps> = ({ tabs, activeKey, onTabChange }) => {
  return (
    <Tabs 
    style={{width:'min-content'}}
      activeKey={activeKey} 
      onChange={onTabChange}
      tabBarGutter={12} // Adjusts the spacing between tabs
      tabBarStyle={{
        borderRadius: '8px',
        padding: '8px',
        backgroundColor: Theme.colors.tabsColor, // Background color of the tab container
      }}
    >
      {tabs.map((tab:any) => (
          <TabPane 
            tab={<span className={`custom-tab ${activeKey === tab.key ? 'active' : ''}`}>{tab.label}</span>} 
            key={tab.key} 
          />
        ))}
    </Tabs>
  );
};

export default CustomTabs;
